<template>
  <div class="about">
    <v-container>
      <v-row justify="center">
        <v-col>
          <h1>Analysen</h1>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col
          >Lorem ipsum dolor sit amet. At vero eos et accusam et justo duo
          dolores et ea rebum. Stet clita kasd gubergren.</v-col
        >
      </v-row>
      <v-row justify="center" class="text-left">
        <v-col>
          <v-card elevation="3" height="100%">
            <v-card-title>Analyse 1</v-card-title>
            <v-card-subtitle
              >Aktualisierung: 12:00 Uhr, 12.12.2021</v-card-subtitle
            >
            <v-card-text>
              <div id="chartTest" style="width: 100%; height: 300px; background: #ededed;"></div>
              </v-card-text
            >
          </v-card>
        </v-col>
        <v-col
          ><v-card elevation="3" height="100%"
            ><v-card-title>Analyse 2</v-card-title
            ><v-card-subtitle
              >Aktualisierung: 12:00 Uhr, 12.12.2021</v-card-subtitle
            ><v-card-text>
              <div id="chartTest" style="width: 100%; height: 300px; background: #ededed;"></div></v-card-text
            ></v-card
          ></v-col
        >
      </v-row>
      <v-row justify="center" class="text-left">
        <v-col
          ><v-card elevation="3"
            ><v-card-title>Analyse 3</v-card-title
            ><v-card-subtitle
              >Aktualisierung: 12:00 Uhr, 12.12.2021</v-card-subtitle
            ><v-card-text
              >1 - Lorem ipsum dolor sit amet. At vero eos et accusam et justo
              duo dolores et ea rebum. Stet clita kasd gubergren.</v-card-text
            ></v-card
          ></v-col
        >
        <v-col
          ><v-card elevation="3"
            ><v-card-title>Analyse 4</v-card-title
            ><v-card-subtitle
              >Aktualisierung: 12:00 Uhr, 12.12.2021</v-card-subtitle
            ><v-card-text
              >1 - Lorem ipsum dolor sit amet. At vero eos et accusam et justo
              duo dolores et ea rebum. Stet clita kasd gubergren.</v-card-text
            ></v-card
          ></v-col
        >
      </v-row>
      <v-row justify="center" class="text-left">
        <v-col
          ><v-card elevation="3"
            ><v-card-title>Analyse 5</v-card-title
            ><v-card-subtitle
              >Aktualisierung: 12:00 Uhr, 12.12.2021</v-card-subtitle
            ><v-card-text
              >1 - Lorem ipsum dolor sit amet. At vero eos et accusam et justo
              duo dolores et ea rebum. Stet clita kasd gubergren.</v-card-text
            ></v-card
          ></v-col
        >
        <v-col
          ><v-card elevation="3"
            ><v-card-title>Analyse 6</v-card-title
            ><v-card-subtitle
              >Aktualisierung: 12:00 Uhr, 12.12.2021</v-card-subtitle
            ><v-card-text
              >1 - Lorem ipsum dolor sit amet. At vero eos et accusam et justo
              duo dolores et ea rebum. Stet clita kasd gubergren.</v-card-text
            ></v-card
          ></v-col
        >
      </v-row>
      <v-row justify="center" class="text-left">
        <v-col
          ><v-card elevation="3"
            ><v-card-title>Analyse 7</v-card-title
            ><v-card-subtitle
              >Aktualisierung: 12:00 Uhr, 12.12.2021</v-card-subtitle
            ><v-card-text
              >1 - Lorem ipsum dolor sit amet. At vero eos et accusam et justo
              duo dolores et ea rebum. Stet clita kasd gubergren.</v-card-text
            ></v-card
          ></v-col
        >
        <v-col
          ><v-card elevation="3"
            ><v-card-title>Analyse 8</v-card-title
            ><v-card-subtitle
              >Aktualisierung: 12:00 Uhr, 12.12.2021</v-card-subtitle
            ><v-card-text
              >1 - Lorem ipsum dolor sit amet. At vero eos et accusam et justo
              duo dolores et ea rebum. Stet clita kasd gubergren.</v-card-text
            ></v-card
          ></v-col
        >
      </v-row>
      <v-row justify="center" class="text-left">
        <v-col
          ><v-card elevation="3"
            ><v-card-title>Analyse 9</v-card-title
            ><v-card-subtitle
              >Aktualisierung: 12:00 Uhr, 12.12.2021</v-card-subtitle
            ><v-card-text
              >1 - Lorem ipsum dolor sit amet. At vero eos et accusam et justo
              duo dolores et ea rebum. Stet clita kasd gubergren.</v-card-text
            ></v-card
          ></v-col
        >
        <v-col
          ><v-card elevation="3"
            ><v-card-title>Analyse 10</v-card-title
            ><v-card-subtitle
              >Aktualisierung: 12:00 Uhr, 12.12.2021</v-card-subtitle
            ><v-card-text
              >1 - Lorem ipsum dolor sit amet. At vero eos et accusam et justo
              duo dolores et ea rebum. Stet clita kasd gubergren.</v-card-text
            ></v-card
          ></v-col
        >
      </v-row>
    </v-container>
    <v-container justify="center">
      <quick-navigation />
    </v-container>
  </div>
</template>

<script>
import QuickNavigation from "../components/QuickNavigation";

export default {
  name: "About",

  components: {
    QuickNavigation,
  },
};
</script>